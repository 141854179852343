<template >
  <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="title my-16"><span>The Etho Protocol</span> Blockchain Bridge</h2>
  <v-card
    max-width="600"
    class="my-16 px-4 py-10"
  >
    <v-tabs
      background-color="#840032"
      center-active
      dark
      class="pb-3"
    >

      <v-tab @click="showEthBridge=true, showBscBridge=false">ETH</v-tab>
      <v-tab @click="showEthBridge=false, showBscBridge=true">BSC</v-tab>

    </v-tabs>
    <EthBridge v-if="showEthBridge"></EthBridge>
    <BscBridge v-if="showBscBridge"></BscBridge>
  </v-card>
  </div>
  </div>
  </div>
</template>
<script>
/* eslint-disable camelcase */
//import Vue from 'vue'
// <v-tab @click="showEthBridge=false, showBscBridge=true">BSC</v-tab>
// <v-tab @click="showEthBridge=true, showBscBridge=false">ETH</v-tab>
import EthBridge from "./ethbridge";	
import BscBridge from "./bscbridge";

//import firebase from 'firebase/app'
//import 'firebase/firestore'


export default {
  name: "Bridge",
	components: {	
    EthBridge,	
    BscBridge,	
  },
  data() {
    return {
      showEthBridge: true, // true
      showBscBridge: false, // false
    }
  },

};
</script>
